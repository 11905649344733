import React, { ComponentType, useMemo } from 'react';
import dynamic from 'next/dynamic';

import { EModalTypes } from '@olo-web/types/enums';
import {
  Drawer,
  DrawerOverlay,
  DrawerProps,
  Modal,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/react';

const OrderClosedModal = dynamic(() => import('./OrderClosedModal'));
const ItemsInOrderModal = dynamic(() => import('./ItemsInOrderModal'));
const ItemDetailsModal = dynamic(() => import('./ItemDetailsModal'));
const OrderDetailsModal = dynamic(() => import('./OrderDetailsModal'));
const LoginModal = dynamic(() => import('./LoginModal'));
const SignUpModal = dynamic(() => import('./SignUpModal'));
const MerchantLocationModal = dynamic(
  () => import('./MerchantLocationModal/MerchantLocationModal.ui')
);
const ApplyOffersModal = dynamic(() => import('./ApplyOffersModal'));
const ForgotPasswordModal = dynamic(() => import('./ForgotPasswordModal/ForgotPasswordModal.ui'));
const OrderTimeModal = dynamic(() => import('./OrderTimeModal'));
const CustomerDeliverySavedAddress = dynamic(
  () => import('./CustomerDeliveryModal/CustomerDeliverySavedAddress.ui')
);
const CustomerDeliveryModalAddress = dynamic(
  () => import('./CustomerDeliveryModal/CustomerDeliveryModal.Address.ui')
);
const RequestEmailReceiptModal = dynamic(
  () => import('./RequestEmailReceiptModal/RequestEmailReceiptModal.ui')
);
const SBAContinueToCheckoutModal = dynamic(
  () => import('./SBAContinueToCheckoutModal/SBAContinueToCheckoutModal')
);
const InitDineInOrderModal = dynamic(() => import('./InitDineInOrderModal'));
const LeaveTableModal = dynamic(() => import('./LeaveTableModal/LeaveTableModal.ui'));
const TableReadyModal = dynamic(() => import('./TableReadyModal/TableReadyModal.ui'));
const TableCheckoutModal = dynamic(() => import('./TableCheckoutModal/TableCheckoutModal.ui'));
const ConfirmFulfillmentTimeModal = dynamic(
  () => import('./ConfirmFulfillmentTimeModal/ConfirmFulfillmentTimeModal.ui')
);
const UpsellModal = dynamic(() => import('./UpsellModal'));
const AddGuestModal = dynamic(() => import('./AddGuestModal/AddGuestModal.ui'));
const GuestCompletesSplitModal = dynamic(
  () => import('./GuestCompletesSplitModal/GuestCompletesSplitModal.ui')
);
const OrderIsResetModal = dynamic(() => import('./OrderIsResetModal/OrderIsResetModal.ui'));
const InviteOthersModal = dynamic(() => import('./InviteOthersModal/InviteOthersModal.ui'));
const UnknownErrorModal = dynamic(() => import('./UnknownErrorModal/UnknownErrorModal.ui'));
const CustomerFeedbackPrompt = dynamic(
  () => import('./CustomerFeedbackPrompt/CustomerFeedbackPrompt.ui')
);
const CustomerFeedbackThankYou = dynamic(
  () => import('./CustomerFeedbackPrompt/CustomerFeedbackThankYou.ui')
);
const SomethingWentWrongModal = dynamic(
  () => import('./SomethingWentWrongModal/SomethingWentWrongModal.ui')
);
const StartATabModal = dynamic(() => import('./StartATabModal/StartATabModal.ui'));
const LoyaltyQRCodeModal = dynamic(() => import('./LoyaltyQRCodeModal/LoyaltyQRCodeModal.ui'));
const CookieModal = dynamic(() => import('./CookieModal/CookieModal.ui'));

import { useModalState, useModalDispatch, useScreenSizeState } from '@olo-web/client-state';

type TModalListenerProps = {
  Component: ComponentType<any> | null;
  isDrawerOnXsDown?: boolean;
  drawerProps?: DrawerProps;
  modalProps?: ModalProps;
};

export function ModalListener(): JSX.Element {
  const { isXsDown } = useScreenSizeState();
  const modalState = useModalState();
  const modalDispatch = useModalDispatch();
  const onClose = () => {
    modalState?.modalContext?.onClose?.apply();

    if (!modalState?.modalContext?.preventDefault) {
      modalDispatch({ type: 'CLOSE_MODAL' });
    }
  };

  const { Component, isDrawerOnXsDown, drawerProps, modalProps } =
    useMemo((): TModalListenerProps => {
      switch (modalState?.modalKey) {
        case EModalTypes.LOGIN:
          return {
            Component: LoginModal,
            isDrawerOnXsDown: true,
          };
        case EModalTypes.SIGNUP:
          return {
            Component: SignUpModal,
            isDrawerOnXsDown: true,
          };
        case EModalTypes.SOMETHING_WENT_WRONG:
          return {
            Component: SomethingWentWrongModal,
            isDrawerOnXsDown: true,
          };
        case EModalTypes.UPSELL:
          return {
            Component: UpsellModal,
            isDrawerOnXsDown: true,
          };
        case EModalTypes.TABLE_READY:
          return {
            Component: TableReadyModal,
            isDrawerOnXsDown: true,
          };
        case EModalTypes.TABLE_CHECKOUT:
          return {
            Component: TableCheckoutModal,
            isDrawerOnXsDown: true,
          };
        case EModalTypes.INIT_DINEIN_ORDER:
          return {
            Component: InitDineInOrderModal,
            isDrawerOnXsDown: true,
          };
        case EModalTypes.SBA_CONTINUE_TO_CHECKOUT:
          return {
            Component: SBAContinueToCheckoutModal,
            isDrawerOnXsDown: true,
          };
        case EModalTypes.APPLY_OFFERS:
          return {
            Component: ApplyOffersModal,
            isDrawerOnXsDown: true,
          };
        case EModalTypes.ITEM_DETAILS:
          return {
            Component: ItemDetailsModal,
            isDrawerOnXsDown: true,
          };
        case EModalTypes.ORDER_DETAILS:
          return {
            Component: OrderDetailsModal,
            isDrawerOnXsDown: true,
          };
        case EModalTypes.MERCHANT_LOCATION:
          return {
            Component: MerchantLocationModal,
            isDrawerOnXsDown: true,
          };
        case EModalTypes.CUSTOMER_DELIVERY_ADDRESS:
          return {
            Component: CustomerDeliveryModalAddress,
            isDrawerOnXsDown: true,
          };
        case EModalTypes.CUSTOMER_DELIVERY_SAVED_ADDRESSES:
          return {
            Component: CustomerDeliverySavedAddress,
            isDrawerOnXsDown: true,
          };
        case EModalTypes.ORDER_TIME:
          return {
            Component: OrderTimeModal,
            isDrawerOnXsDown: true,
          };
        case EModalTypes.FORGET_PASSWORD:
          return {
            Component: ForgotPasswordModal,
            isDrawerOnXsDown: true,
          };
        case EModalTypes.REQUEST_EMAIL_RECEIPT:
          return {
            Component: RequestEmailReceiptModal,
            isDrawerOnXsDown: true,
          };
        case EModalTypes.LEAVE_TABLE:
          return {
            Component: LeaveTableModal,
            isDrawerOnXsDown: true,
          };
        case EModalTypes.ORDERED_ITEMS:
          return {
            Component: ItemsInOrderModal,
            isDrawerOnXsDown: true,
          };
        case EModalTypes.CLOSED_ORDER:
          return {
            Component: OrderClosedModal,
            isDrawerOnXsDown: true,
          };
        case EModalTypes.CONFIRM_FULFILLMENT_TIME:
          return {
            Component: ConfirmFulfillmentTimeModal,
            isDrawerOnXsDown: true,
          };
        case EModalTypes.ADD_GUEST:
          return {
            Component: AddGuestModal,
            isDrawerOnXsDown: true,
          };
        case EModalTypes.GUEST_COMPLETES_SPLIT:
          return {
            Component: GuestCompletesSplitModal,
            isDrawerOnXsDown: true,
          };
        case EModalTypes.ORDER_IS_RESET:
          return {
            Component: OrderIsResetModal,
            isDrawerOnXsDown: true,
          };
        case EModalTypes.INVITE_OTHERS:
          return {
            Component: InviteOthersModal,
            isDrawerOnXsDown: true,
          };
        case EModalTypes.UNKNOWN_ERROR:
          return {
            Component: UnknownErrorModal,
            isDrawerOnXsDown: true,
          };
        case EModalTypes.CUSTOMER_FEEDBACK_PROMPT:
          return {
            Component: CustomerFeedbackPrompt,
            isDrawerOnXsDown: true,
          };
        case EModalTypes.CUSTOMER_FEEDBACK_THANK_YOU:
          return {
            Component: CustomerFeedbackThankYou,
            isDrawerOnXsDown: true,
          };
        case EModalTypes.START_A_TAB:
          return {
            Component: StartATabModal,
            isDrawerOnXsDown: true,
          };
        case EModalTypes.LOYALTY_QR_CODE:
          return {
            Component: LoyaltyQRCodeModal,
            isDrawerOnXsDown: true,
          };
        case EModalTypes.COOKIE_BANNER:
          return {
            Component: CookieModal,
            isDrawerOnXsDown: true,
          };
        default:
          if (modalState?.modalKey) {
            console.warn('No matching modal found for key: ', modalState?.modalKey);
          }
          return {
            Component: null,
          };
      }
    }, [modalState?.modalKey]);

  if (isXsDown && isDrawerOnXsDown) {
    return (
      <Drawer
        {...modalState.modalProps}
        isOpen={!!modalState?.modalKey}
        onClose={onClose}
        placement="bottom"
        allowPinchZoom
        useInert={false}
        {...(drawerProps || {})}
      >
        <DrawerOverlay padding="0px" zIndex={1400} />
        {Component && <Component />}
      </Drawer>
    );
  }

  return (
    <Modal
      {...modalState.modalProps}
      isOpen={!!modalState?.modalKey}
      onClose={onClose}
      scrollBehavior="inside"
      preserveScrollBarGap
      useInert={false}
      {...(modalProps || {})}
    >
      <ModalOverlay />
      {Component && <Component />}
    </Modal>
  );
}
